<template>
  <table
    v-if="logs.length !== 0"
    class="table table-hover table-responsive table-bordered"
  >
    <tbody>
      <tr v-for="log in logs" :key="log.id">
        <th style="width: 40%">
          {{ convertDate(log.create_at) }}
        </th>
        <td>{{ log.message }}</td>
      </tr>
    </tbody>
  </table>
  <div v-else class="text-center fs-5">Kayıt bulunamadı!</div>
</template>

<script>
import moment from "moment";
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "",
  props: ["bot_id"],
  async setup(props) {
    const id = ref(props.bot_id);
    const store = useStore();

    const convertDate = (date) => moment(date).format("Do MMMM YYYY H:mm:ss");

    await store.dispatch("macd_bots/getLogs", { pk: id.value });

    const logs = computed(() => store.getters["macd_bots/getLogs"]);

    return { convertDate, logs };
  },
};
</script>

<style scoped></style>
