<template>
  <dashboard-layout>
    <div class="row g-3">
      <div class="col-lg-7">
        <div class="card p-2 mb-3">
          <h5 class="card-header">Aksiyonlar</h5>
          <div v-if="pendingBot">
            <div class="d-flex justify-content-center p-4">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
          <div v-else>
            <div
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered modal-xl">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"></h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div class="text-center mb-4">
                      <div
                        class="alert alert-primary d-flex align-items-center"
                        role="alert"
                      >
                        <i class="bi bi-info-circle-fill me-3 fs-4"></i>
                        <div>
                          Birleştirmek istediğiniz tradingview alertinin ilgili
                          kısımlarına aşağıdaki bilgileri doldurunuz.
                        </div>
                      </div>
                    </div>
                    <h5>Webhook URL:</h5>
                    <div class="px-5">
                      <div class="bg-dark py-3 px-5 rounded">
                        <code class="text-light">
                          https://api.hkajet.com/macd_bot/alert/
                        </code>
                      </div>
                    </div>
                    <h5 class="mt-4">Message:</h5>
                    <div class="d-flex justify-content-evenly">
                      <div>
                        <h5>Long alert için:</h5>
                        <div class="bg-dark py-3 px-5 rounded">
                          <code class="text-light">
                            {<br />
                            "position": "{{ signals[0].position }}", <br />
                            "bot_id": {{ macdBot.id }}, <br />
                            "signal_id": {{ signals[0].id }},<br />
                            "token": "{{ signals[0].token }}",<br />
                            "price": {{ close }},<br />
                            "time_interval": "5m" <br />
                            }
                          </code>
                        </div>
                      </div>
                      <div>
                        <h5>Short alert için:</h5>
                        <div class="bg-dark py-3 px-5 rounded">
                          <code class="text-light">
                            {<br />
                            "position": "{{ signals[1].position }}", <br />
                            "bot_id": {{ macdBot.id }}, <br />
                            "signal_id": {{ signals[1].id }},<br />
                            "token": "{{ signals[1].token }}",<br />
                            "price": {{ close }},<br />
                            "time_interval": "5m" <br />
                            }
                          </code>
                        </div>
                      </div>
                    </div>
                    <div
                      class="alert alert-warning d-flex align-items-center mt-3"
                      role="alert"
                    >
                      <i class="bi bi-exclamation-triangle-fill me-3 fs-4"></i>
                      <div>
                        "time_interval" karşısına çalıştığınız grafiğin zaman
                        aralığını girmelisiniz!<br /><b>Örneğin:</b> "5m" /
                        "30m" / "1h" / "D" / "W"
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Kapat
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body d-flex">
              <button
                class="btn btn-outline-success me-2"
                @click="startBot()"
                :disabled="
                  macdBot.status === 'active' ||
                  macdBot.status === 'deleted' ||
                  start
                "
              >
                Başlat
                <i
                  :class="[
                    {
                      'spinner-border spinner-border-sm': start,
                    },
                    { 'bi bi-play': !start },
                  ]"
                ></i>
              </button>
              <button
                class="btn btn-outline-secondary me-2"
                @click="stopBot()"
                :disabled="
                  macdBot.status === 'stopped' ||
                  macdBot.status === 'deleted' ||
                  stop
                "
              >
                Durdur
                <i
                  :class="[
                    {
                      'spinner-border spinner-border-sm': stop,
                    },
                    { 'bi bi-pause': !stop },
                  ]"
                ></i>
              </button>
              <button
                class="btn btn-outline-secondary me-2"
                :disabled="macdBot.status === 'deleted'"
              >
                Güncelle <i class="bi bi-arrow-repeat"></i>
              </button>
              <button
                class="btn btn-danger me-2"
                @click="deleteBot()"
                :disabled="macdBot.status === 'deleted'"
              >
                Sil <i class="bi bi-trash"></i>
              </button>
              <button class="btn btn-success me-2" disabled>
                Export Excel <i class="bi bi-file-earmark-excel"></i>
              </button>
              <button
                type="button"
                class="btn btn-outline-info ms-auto"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Kod <i class="bi bi-code-square"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="card p-2">
          <h5 class="card-header">Bot Bilgileri</h5>
          <div class="card-body w-100 pt-0">
            <SuspenseWithError>
              <template #default>
                <detail :bot_id="bot_id" />
              </template>
              <template #fallback>
                <div class="d-flex justify-content-center p-4">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </template>
              <template #error> I faild to load! </template>
            </SuspenseWithError>
          </div>
        </div>
        <div class="card p-2 mt-3">
          <h5 class="card-header d-flex justify-content-between">
            <div>İşlemler</div>
            <button
              class="btn btn-success me-2"
              :disabled="exportTransactionLoading"
              @click="exportTransactions()"
            >
              Export Excel
              <i
                :class="[
                  {
                    'spinner-border spinner-border-sm':
                      exportTransactionLoading,
                  },
                  { 'bi bi-file-earmark-excel': !exportTransactionLoading },
                ]"
              ></i>
            </button>
          </h5>
          <div class="card-body w-100 p-0">
            <SuspenseWithError>
              <template #default>
                <transactions :bot_id="bot_id" />
              </template>
              <template #fallback>
                <div class="d-flex justify-content-center p-4">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </template>
              <template #error> I faild to load!</template>
            </SuspenseWithError>
          </div>
        </div>
      </div>
      <div class="col-lg-5 card p-2">
        <h5 class="card-header d-flex justify-content-between">
          <div>Son Aksiyonlar</div>
          <button class="btn btn-success me-2" disabled>
            Export Excel <i class="bi bi-file-earmark-excel"></i>
          </button>
        </h5>
        <div class="card-body">
          <SuspenseWithError>
            <template #default>
              <log :bot_id="bot_id" />
            </template>
            <template #fallback>
              <div class="d-flex justify-content-center p-4">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </template>
            <template #error>I faild to load!</template>
          </SuspenseWithError>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from "@/layouts/Dashboard.layout.vue";

import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Detail from "@/components/Dashboard/MacdBot/Details/Detail.vue";
import Transactions from "@/components/Dashboard/MacdBot/Details/Transactions.vue";
import Log from "@/components/Dashboard/MacdBot/Details/Log.vue";
import SuspenseWithError from "../../../../components/SuspenseWithError.vue";
import { computed } from "@vue/runtime-core";

export default {
  components: { DashboardLayout, Detail, Transactions, Log, SuspenseWithError },

  setup() {
    const close = "{{close}}";
    const store = useStore();
    const route = useRoute();

    const bot_id = parseInt(route.params.id);

    const pendingBot = computed(() => store.state.macd_bots.pendingBot);
    const macdBot = computed(() => store.state.macd_bots.bot);
    const signals = computed(() => store.state.macd_bots.signals);

    const start = computed(() => store.state.macd_bots.macdBotStartLoading);
    const stop = computed(() => store.state.macd_bots.macdBotStopLoading);
    const exportTransactionLoading = computed(
      () => store.state.macd_bots.macdBotTransactionExportLoading
    );

    const startBot = () => {
      store.commit("macd_bots/startLoading");
      store.dispatch("macd_bots/startBot", { pk: bot_id });
    };

    const stopBot = () => {
      store.commit("macd_bots/stopLoading");
      store.dispatch("macd_bots/stopBot", { pk: bot_id });
    };

    const deleteBot = () => {
      store.dispatch("macd_bots/deleteBot", { pk: bot_id });
    };

    const exportTransactions = () => {
      store.dispatch("macd_bots/exportTransactions", { pk: bot_id });
    };
    return {
      bot_id,
      close,
      pendingBot,
      macdBot,
      start,
      stop,
      signals,
      startBot,
      stopBot,
      deleteBot,
      exportTransactions,
      exportTransactionLoading,
    };
  },
};
</script>
