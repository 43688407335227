<template>
  <slot v-if="error" name="error"></slot>
  <Suspense v-else>
    <template #default>
      <slot name="default"> </slot>
    </template>
    <template #fallback>
      <slot name="fallback"> </slot>
    </template>
  </Suspense>
</template>

<script>
import { onErrorCaptured, ref } from "vue";
export default {
  name: "SuspenseWithError",
  setup() {
    const error = ref(null);

    onErrorCaptured((err) => {
      error.value = err;
      console.log(err);
      return true;
    });
    return { error };
  },
};
</script>

<style></style>
