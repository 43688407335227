<template>
  <div class="accordion p-3" id="accordionExample">
    <div
      v-for="transaction in transactions"
      :key="transaction.id"
      class="accordion-item"
    >
      <h2 class="accordion-header" :id="`heading_${transaction.id}`">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="`#collapse_${transaction.id}`"
          aria-expanded="false"
          :aria-controls="`collapse_${transaction.id}`"
        >
          Transaction {{ transaction.id }} ({{ transaction.position }} /
          {{ transaction.status }})
        </button>
      </h2>
      <div
        :id="`collapse_${transaction.id}`"
        class="accordion-collapse collapse"
        :aria-labelledby="`heading_${transaction.id}`"
        data-bs-parent="#accordionExample"
      >
        <Transaction
          :transaction="transaction"
          :wallet="getWallet(transaction.id)"
          :alert="getAlert(transaction.id)"
          :convertDate="convertDate"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import moment from "moment";
import Transaction from "./Transaction.vue";
export default {
  components: { Transaction },
  props: ["bot_id"],
  async setup(props) {
    const id = ref(props.bot_id);

    const store = useStore();

    await store.dispatch("macd_bots/getBot", { pk: id.value });

    const transactions = computed(
      () => store.getters["macd_bots/getTransactions"]
    );
    const alerts = computed(() => store.getters["macd_bots/getAlerts"]);

    const walletsForTransaction = computed(
      () => store.getters["macd_bots/getWalletsForTransaction"]
    );

    const getAlert = (trans_id) => {
      return computed(() => store.getters["macd_bots/getAlert"](trans_id))
        .value;
    };
    const getWallet = (trans_id) => {
      return computed(() =>
        store.getters["macd_bots/getWalletForTransaction"](trans_id)
      ).value;
    };
    const convertDate = (date) => moment(date).format("Do MMMM YYYY H:mm:ss");

    return {
      transactions,
      alerts,
      walletsForTransaction,
      getAlert,
      getWallet,
      convertDate,
    };
  },
};
</script>
