<template>
  <div>
    <table class="table mt-md-4">
      <tbody>
        <tr>
          <th>Bot Id</th>
          <td>{{ macdBot.id }}</td>
        </tr>
        <tr>
          <th>Bot Adı</th>
          <td>{{ macdBot.bot_name }}</td>
        </tr>
        <tr>
          <th>Başlangıç Tarihi</th>
          <td>{{ formatDate(macdBot.start_time) }}</td>
        </tr>
        <tr>
          <th>Bitiş Tarihi</th>
          <td>
            {{ end_time_calc(macdBot.limitless) }}
          </td>
        </tr>
        <tr>
          <th>Hesap / Borsa</th>
          <td>{{ macdBot.account_name }} / {{ macdBot.exchange_account }}</td>
        </tr>
        <tr>
          <th>Pair</th>
          <td>{{ macdBot.pair }}</td>
        </tr>
        <tr>
          <th>Status</th>
          <td>{{ macdBot.status }}</td>
        </tr>
        <tr class="accordion in-table" id="a_case_at_starting">
          <th>Başlangıçta Kasa</th>
          <div class="accordion-item">
            <td class="accordion-header" id="h_case_at_starting">
              <button
                class="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#case_at_starting"
                aria-expanded="false"
                aria-controls="case_at_starting"
              >
                Detaylar
              </button>
            </td>
            <div
              id="case_at_starting"
              class="accordion-collapse collapse"
              aria-labelledby="h_case_at_starting"
              aria-bs-parent="#h_case_at_starting"
            >
              <div class="accordion-body">
                <div v-for="wallet in walletForBot" :key="wallet.id">
                  USD: {{ wallet.old_usd }}
                </div>
              </div>
            </div>
          </div>
        </tr>
        <tr>
          <td class="p-0" colspan="2">
            <div class="accordion" id="signals">
              <div class="accordion-item">
                <h2
                  class="accordion-header fw-bold"
                  id="panelsStayOpen-headingOne"
                >
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                  >
                    Sinyal Long
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div class="accordion-body">
                    <table class="table table-responsive">
                      <tbody>
                        <tr>
                          <th>Sinyal Id</th>
                          <td>{{ signals[0].id }}</td>
                        </tr>
                        <tr>
                          <th>Token</th>
                          <td>{{ signals[0].token }}</td>
                        </tr>
                        <tr>
                          <th>İşlem Tahtası</th>
                          <td>{{ signals[0].transaction_table }}</td>
                        </tr>
                        <tr>
                          <th>Kasa Oranı</th>
                          <td>%{{ signals[0].case_ratio }}</td>
                        </tr>
                        <tr>
                          <th>İşlem Türü</th>
                          <td>{{ signals[0].order_type }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2
                  class="accordion-header fw-bold"
                  id="panelsStayOpen-headingTwo"
                >
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwo"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseTwo"
                  >
                    Sinyal Short
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingTwo"
                >
                  <div class="accordion-body">
                    <table class="table table-responsive">
                      <tbody>
                        <tr>
                          <th>Sinyal Id</th>
                          <td>{{ signals[1].id }}</td>
                        </tr>
                        <tr>
                          <th>Token</th>
                          <td>{{ signals[1].token }}</td>
                        </tr>
                        <tr>
                          <th>İşlem Tahtasu</th>
                          <td>{{ signals[1].transaction_table }}</td>
                        </tr>
                        <tr>
                          <th>Kasa Oranı</th>
                          <td>%{{ signals[1].case_ratio }}</td>
                        </tr>
                        <tr>
                          <th>İşlem Türü</th>
                          <td>{{ signals[1].order_type }}</td>
                        </tr>
                        <tr>
                          <th>Bitiş Noktası Türü</th>
                          <td>{{ signals[1].end_point_type }}</td>
                        </tr>
                        <tr>
                          <th>Bitiş Noktası</th>
                          <td>{{ signals[1].end_point }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import { useStore } from "vuex";
import { ref, computed } from "vue";
export default {
  name: "Macd Bot Details",
  props: ["bot_id"],
  async setup(props) {
    const id = ref(props.bot_id);
    const store = useStore();

    store.commit("macd_bots/resetBot");
    await store.dispatch("macd_bots/getBot", { pk: id.value });

    const macdBot = computed(() => store.getters["macd_bots/getBot"]);
    const signals = computed(() => store.getters["macd_bots/getSignals"]);

    const walletForBot = computed(
      () => store.getters["macd_bots/getWalletForBot"]
    );

    const formatDate = (date) => {
      return moment(date).format("Do MMMM YYYY H:mm:ss");
    };
    const end_time_calc = (limitless) => {
      return !limitless ? formatDate(macdBot.value.end_time) : "Sınırsız";
    };
    return {
      macdBot,
      signals,
      walletForBot,
      formatDate,
      end_time_calc,
    };
  },
};
</script>

<style lang="scss" scoped>
th {
  font-weight: bold;
}
th,
td {
  vertical-align: middle;
}

.accordion-item {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid;
  border-bottom-color: #dee2e6;
}
.accordion-header {
  width: 100%;
  display: block;
}
.accordion-body {
  padding-top: 1rem;
  padding-left: 10px;
}
.accordion-button {
  padding: 0;
  width: 100%;
}
.accordion-button:focus {
  border-color: none;
  box-shadow: none;
}
.accordion-button:not(.collapsed) {
  color: inherit;
  background: none;
}

#signals {
  .accordion-button {
    padding: 8px;
    width: 100%;
  }
}
</style>
