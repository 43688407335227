<template>
  <div class="accordion-body">
    <table class="table table-bordered">
      <tbody>
        <tr>
          <th scope="row">Created Time</th>
          <td>{{ convertDate(transaction.create_time) }}</td>
        </tr>
        <tr>
          <th scope="row">Triggered Time</th>
          <td>{{ convertDate(transaction.trigger_time) }}</td>
        </tr>
        <tr>
          <th scope="row">Position</th>
          <td>{{ transaction.position }}</td>
        </tr>
        <tr>
          <th scope="row">Status</th>
          <td>{{ transaction.status }}</td>
        </tr>
        <tr>
          <th scope="row">Starting Case</th>
          <td>{{ wallet.old_usd }}</td>
        </tr>
        <tr>
          <th scope="row">Ending Case</th>
          <td>{{ wallet.new_usd }}</td>
        </tr>
      </tbody>
    </table>
    <table class="table table-bordered mt-3">
      <thead>
        <tr>
          <th scope="col" class="bg-dark text-light" colspan="2">Alert</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">Alert Id</th>
          <td>{{ alert.id }}</td>
        </tr>
        <tr>
          <th scope="row">Status</th>
          <td>{{ alert.status }}</td>
        </tr>
        <tr>
          <th scope="row">Create Date</th>
          <td>{{ convertDate(alert.create_time) }}</td>
        </tr>
        <tr>
          <th scope="row">Transaction Amount</th>
          <td>{{ alert.transaction_amount }}</td>
        </tr>
        <tr>
          <th scope="row">Action Time</th>
          <td>{{ convertDate(alert.action_time) }}</td>
        </tr>
        <tr>
          <th scope="row">Action Point</th>
          <td>{{ alert.action_point }}</td>
        </tr>
        <tr>
          <th scope="row">Execution Time</th>
          <td>{{ convertDate(alert.execution_time) }}</td>
        </tr>
        <tr>
          <th scope="row">Execution Point</th>
          <td>{{ alert.execution_point }}</td>
        </tr>
        <tr>
          <th scope="row">End Point</th>
          <td>{{ alert.end_point }}</td>
        </tr>
        <tr>
          <th scope="row">Average</th>
          <td>{{ alert.average }}</td>
        </tr>
        <tr>
          <th scope="row">Amount of Transaction</th>
          <td>{{ alert.amount_of_transaction }}</td>
        </tr>
        <tr>
          <th scope="row">Number of orders opened before execute</th>
          <td>{{ alert.before_executed }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "Transaction",
  props: ["transaction", "wallet", "alert", "convertDate"],
};
</script>

<style></style>
